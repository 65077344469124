function invertColor(hexColor) {
    if (typeof hexColor === 'string' && hexColor.length > 0) {
        if (hexColor === "white") return "black";
        hexColor = hexColor.replace("#", "");

        // If the hexColor is 3 characters long, expand it to 6 characters
        if (hexColor.length === 3) {
            hexColor = hexColor
                .split("")
                .map(char => char + char)
                .join("");
        }

        const red = parseInt(hexColor.substring(0, 2), 16);
        const green = parseInt(hexColor.substring(2, 4), 16);
        const blue = parseInt(hexColor.substring(4, 6), 16);

        // Calculate the brightness of the color (0-255)
        const brightness = (0.299 * red + 0.587 * green + 0.114 * blue);

        const backgroundColor = brightness > 128 ? "black" : "white";

        return backgroundColor;
    }
}

export default invertColor;
